"use strict";

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

/* ==========================================================================
Slider
========================================================================== */

/*
 * Description :
 * - Config de base pour tiny-slider (https://github.com/ganlanyuan/tiny-slider), dispo dans components
 * Auteur :
 * - CEF
 * Sommaire :
 */
document.addEventListener('DOMContentLoaded', function () {
  // Configuration pour les sliders
  //  const sliders = [...document.querySelectorAll('[class*="slider"]')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
  var sliders = Array.prototype.slice.call(document.querySelectorAll('[class="slider"]'));

  if (sliders.length > 0) {
    var _iterator = _createForOfIteratorHelper(sliders),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var slider = _step.value;
        slider = tns({
          container: slider,
          items: 1,
          slideBy: 'page',
          autoplay: false,
          controlsText: ['', ''],
          arrowKeys: true,
          gutter: 20,
          rewind: true,
          speed: 500
        });
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }
  } // Configuration pour le composant steps
  //  const slidersStep = [...document.querySelectorAll('[class*="steps__list"]')];//Non compatible avec IE11 et Babel (spread sur une nodelist)


  var slidersStep = Array.prototype.slice.call(document.querySelectorAll('[data-slider="steps"]'));

  if (slidersStep.length > 0) {
    var _iterator2 = _createForOfIteratorHelper(slidersStep),
        _step2;

    try {
      for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
        var sliderStep = _step2.value;
        sliderStep = tns({
          container: sliderStep,
          items: 1,
          slideBy: 'page',
          autoplay: false,
          controls: false,
          nav: false,
          center: true,
          edgePadding: 0,
          responsive: {
            780: {
              disable: true,
              items: 10
            }
          },
          rewind: true,
          speed: 500
        });
      }
    } catch (err) {
      _iterator2.e(err);
    } finally {
      _iterator2.f();
    }
  } // Configuration pour le composant tab


  var tabs = {
    data: {
      headIndex: 0
    },
    onLoad: function onLoad() {
      //      const tabsHead = [...document.querySelectorAll('[class="tabs__head"')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var tabsHead = Array.prototype.slice.call(document.querySelectorAll('[class="tabs__head"')); //      const tabsContent = [...document.querySelectorAll('[class="tabs__content"')];//Non compatible avec IE11 et Babel (spread sur une nodelist)

      var tabsContent = Array.prototype.slice.call(document.querySelectorAll('[class="tabs__content"'));
      var tabsHeadItem;
      var tabsHeadSlider;
      var tabsContentSlider;

      if (tabsContent.length > 0) {
        tabsContent.map(function (el) {
          tabsContentSlider = tns({
            container: el,
            items: 1,
            slideBy: 'page',
            autoplay: false,
            controls: false,
            nav: false,
            center: true,
            rewind: true,
            speed: 500,
            touch: false,
            mouseDrag: false
          });
        });
      }

      if (tabsHead.length > 0) {
        tabsHead.map(function (el) {
          tabsHeadSlider = tns({
            container: el,
            items: 1,
            slideBy: 1,
            autoplay: false,
            controls: false,
            nav: false,
            center: true,
            rewind: true,
            speed: 500,
            mouseDrag: false,
            touch: false,
            controlsText: '  ',
            responsive: {
              320: {
                controls: true,
                items: 1,
                slideBy: 1,
                center: false
              },
              960: {
                disable: true
              }
            }
          }); //          tabsHeadItem = [...el.querySelectorAll('li')];//Non compatible avec IE11 et Babel (spread sur une nodelist)

          tabsHeadItem = Array.prototype.slice.call(el.querySelectorAll('li'));
          tabsHeadItem.map(function (item, index) {
            item.addEventListener('click', function () {
              tabsContentSlider.goTo(index);
              item.classList.add('tns-slide-active');
              tabs.updateTabsHead(tabsHeadItem, index);
            });
          });
        });

        var updateContent = function updateContent(info) {
          var newIndex = info.index;
          tabsContentSlider.goTo(newIndex);
        };

        tabsHeadSlider.events.on('transitionStart', updateContent);
      }
    },
    updateTabsHead: function updateTabsHead(target, index) {
      var notActive = target.filter(function (el, i) {
        return i !== index;
      });
      notActive.map(function (el) {
        el.classList.remove('tns-slide-active');
      });
    },
    init: function init() {
      tabs.onLoad();
    }
  };
  tabs.init();
});